/* eslint-disable max-statements */
/* eslint-disable import/no-cycle */
import TimeSlots from './api/timeSlots';
import MenuDay from './api/menuDay';
import Orders from './api/orders';
import Header from './api/header';
import Categories from './api/categories';
import Feedback from './api/feedback';
import Calender from './api/calender';
import Faqs from './api/faqs';
import News from './api/news';
import Legal from './api/legal';
import Stores from './api/stores';
import ExternalUser from './api/externalUser';

import {
    saveAccessToken,
    saveRefreshToken,
    saveIsIngkaUser,
    saveLang,
} from '../services/localStorage/jwt';
import Helpers from '../helpers/index';
import router from '../routes';
import URL_CONSTANT from '../routes/urlConstant';

async function fetchTimes({ commit, state }) {
    try {
        // commit('SET_CURRENT_FETCH_STATUS', true);
        const timeSlots = await TimeSlots.getAll(`/api/time_slots/${state.selectionDay}/${state.orderType}/`);
        if (timeSlots) {
            commit('SET_CURRENT_TIME_LIST', timeSlots.time_slots);
            commit('SET_CURRENT_SELECTION_DAY_PRINT', timeSlots.selection_day_print);
            commit('SET_CURRENT_SELECTION_DAY_TRANSL', timeSlots.translations.text_selection_day_print);
            commit('SET_CURRENT_FETCH_STATUS', false);
            commit('SET_CURRENT_SHIFT_NAMES', timeSlots.shift_names);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function fetchActiveStores({ commit }, payload) {
    try {
        // Cambiar esta linea como estaba antes
        const url = payload ? `/api/active_stores/${payload}/` : '/api/active_stores/';
        const updateEndpoint = url.replace(':country', payload);
        const result = await Stores.getAllActiveStores(updateEndpoint);
        if (result) {
            commit('SET_CURRENT_ACTIVE_STORES', result);
        }
    } catch (error) {
        console.log(error);
    }
}

async function fetchActiveCountrys({ commit }) {
    try {
        const result = await Stores.getAllCountryStores();
        if (result) {
            commit('SET_COUNTRY_STORES', result);
        }
    } catch (error) {
        console.log(error);
    }
}

async function checkStep({ state }) {
    if (state.orderType === '') {
        router.push(URL_CONSTANT.home);
    }
}

async function setTime({ commit }, payload) {
    commit('SET_CURRENT_TIME_SELECTED', payload);
}

async function setShiftName({ commit }, payload) {
    commit('SET_CURRENT_SELECTION_SHIFT_NAME', payload);
}

async function setSelectionDay({ commit }, payload) {
    commit('SET_CURRENT_SELECTION_DAY', payload);
}

async function setOrderType({ commit }, payload) {
    commit('SET_CURRENT_ORDER_TYPE', payload);
}

async function fetchProduct({ commit, state, dispatch }, payload) {
    const { edit } = payload;
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        // eslint-disable-next-line max-len
        const url = `/api/menu_day/${state.selectionDay}/${state.selectionShiftName}/${edit ? `${state.editIdOrder}/` : ''}`;
        const productList = await MenuDay.getAll(url);
        if (productList) {
            dispatch('setProductList', productList.items);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function setProductList({ commit }, payload) {
    commit('SET_CURRENT_PRODUCT_LIST', payload);
}

function filterProduct({ commit, state }, payload) {
    const newArray = state.productList.filter((each) => {
        let condition = false;
        for (let index = 0; index < payload.length; index += 1) {
            if (each.category.toString() === payload[index]) {
                condition = true;
            }
        }
        return condition;
    });
    commit('SET_CURRENT_FILTER_PRODUCT_LIST', newArray);
}

function countProduct({ commit }, payload) {
    commit('SET_CURRENT_PRODUCT_COUNT', payload);
}

async function setProducts({ commit }, payload) {
    commit('SET_CURRENT_PRODUCT_SELECTED', payload);
}

// eslint-disable-next-line max-statements
async function createOrder({ state, dispatch, commit }, payload = '') {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const hours = state.timeSelected.split('-');
        const data = {
            id_store: state.storeId,
            items: state.productSelected,
            observations: payload,
            selection_day: state.selectionDay,
            hour_from: hours[0].trim(),
            hour_to: hours[1].trim(),
            order_type: state.orderType,
            dt_hour_from: state.fraqTime.hourFrom,
            dt_hour_to: state.fraqTime.hourTo,
            id_menu: state.menuId,
        };
        dispatch('setProductList', []);
        const result = await Orders.createOrder(data);
        if (result) {
            dispatch('fetchHeader');
            commit('SET_CURRENT_FETCH_STATUS', true);
            commit('SET_CURRENT_ORDER_MESSAGE_FINAL', state.translations?.make_order?.confirmed_reservation);
            commit('SET_CURRENT_ORDER_MESSAGE_FINAL_DESC', state.translations?.make_order?.email_reservation);
            return { status: true };
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        commit('SET_CURRENT_ORDER_MESSAGE_FINAL', error.data.errors[0]);
        return { status: false };
    }
    commit('SET_CURRENT_FETCH_STATUS', false);
    commit('SET_CURRENT_ORDER_MESSAGE_FINAL', state.translations.order_error);
    return { status: false };
}

async function fetchActiveOrders({ commit }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const result = await Orders.activeOrders();
        if (result) {
            commit('SET_CURRENT_ACTIVE_ORDERS', result);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function fetchLastActiveOrder({ commit }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const result = await Orders.activeOrders();
        if (result) {
            commit('SET_CURRENT_LAST_ACTIVE_ORDER', result[0]);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function updateOrder({ dispatch, commit, state }, payload) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const urlSamples = '/api/update_order/:id/';
        const updateEndpoint = urlSamples.replace(':id', payload.id);
        const response = await Orders.updateOrder(
            payload.data,
            updateEndpoint,
        );

        if (response) {
            dispatch('fetchHeader');
            commit('SET_CURRENT_FETCH_STATUS', true);
            if (payload.data.operation === 'pick_up') {
                commit('SET_CURRENT_ORDER_MESSAGE_FINAL', state.translations?.reservations?.picked_up_order);
                commit('SET_CURRENT_ORDER_MESSAGE_FINAL_DESC', ' ');
            } else {
                commit('SET_CURRENT_ORDER_MESSAGE_FINAL', state.translations?.cancel_order?.cancelled_reservation);
                commit('SET_CURRENT_ORDER_MESSAGE_FINAL_DESC', state.translations?.cancel_order?.email_canteen);
            }
            return { status: true };
        }

    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        commit('SET_CURRENT_ORDER_MESSAGE_FINAL', error.data.errors[0]);
        return { status: false };
    }
    commit('SET_CURRENT_FETCH_STATUS', false);
    commit('SET_CURRENT_ORDER_MESSAGE_FINAL', state.translations.order_error);
    return { status: false };
}

// eslint-disable-next-line max-statements
async function fetchHeader({ commit, dispatch }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const response = await Header.get();
        if (response) {
            commit('SET_CURRENT_SELECTION_ALLOWED', response.selection_allowed);
            commit('SET_CURRENT_COUNT_ACTIVE_ORDERS', response.num_active_orders);
            commit('SET_CURRENT_COUNT_NEWS', response.num_news);
            commit('SET_CURRENT_OPENING_TIME', response.opening_time);
            commit('SET_CURRENT_CLOSING_TIME', response.closing_time);
            commit('SET_CURRENT_FIRST_NAME', response.first_name);
            commit('SET_CURRENT_ADMIN_ACCESS', response.access_canteen_admin_allowed);
            commit('SET_CURRENT_ID_INGKA', response.id_ingka);
            commit('SET_CURRENT_LANGUAGE', response.language);
            commit('SET_CURRENT_TRASLATIONS', response.translations);
            commit('SET_CURRENT_STORE_ID', response.id_store);
            commit('SET_CURRENT_STORE_NAME', response.store_friendly_name);
            commit('SET_CURRENT_CURRENCY', response.currency_symbol);
            commit('SET_COUNTRY_CODE', response.store_country_code);
            dispatch('fetchActiveCountrys');
            dispatch('fetchActiveStores', response.store_country_code);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function fetchCategories({ commit }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const response = await Categories.get();
        if (response) {
            commit('SET_CURRENT_CATEGORIES', response);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function createFeedback({ dispatch, commit }, payload) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const result = await Feedback.createfeedback(payload);
        if (result) {
            dispatch('fetchHeader');
            commit('SET_CURRENT_FETCH_STATUS', false);
            return { status: true };
        }
    } catch (error) {
        console.log(error);
    }
    commit('SET_CURRENT_FETCH_STATUS', false);
    return { status: false };
}

async function fetchCalender({ commit, state }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const result = await Calender.get(`/api/calendar/${state.orderType}/`);
        if (result) {
            commit('SET_CURRENT_CALENDER', result);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function editOrder({ commit, dispatch }, payload) {
    const {
        day,
        // eslint-disable-next-line camelcase
        format_day,
        id,
        items,
        observations,
        // eslint-disable-next-line camelcase
        time_slot,
        // eslint-disable-next-line camelcase
        shift_name,
        // eslint-disable-next-line camelcase
        order_type,
    } = payload;

    const selectedItems = items.filter((each) => each.availability);

    commit('SET_CURRENT_PRODUCT_SELECTED', []);
    dispatch('setEditStatus', true);
    commit('SET_CURRENT_SELECTION_DAY', format_day);
    commit('SET_CURRENT_SELECTION_DAY_PRINT', day);
    commit('SET_CURRENT_EDIT_ID_ORDER', id);
    commit('SET_CURRENT_PRODUCT_SELECTED', selectedItems);
    commit('SET_CURRENT_TIME_SELECTED', time_slot);
    commit('SET_CURRENT_EDIT_OBSERVATION', observations);
    commit('SET_CURRENT_SELECTION_SHIFT_NAME', shift_name);
    commit('SET_CURRENT_ORDER_TYPE', order_type);
    return true;
}

async function modifyActualOrder({ commit, state, dispatch }, payload) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const urlOrder = '/api/modify_order/:id/';
        const updateEndpoint = urlOrder.replace(':id', state.editIdOrder);
        const data = {
            items: state.productSelected,
            observations: payload,
        };
        const response = await Orders.modifyOrder(data, updateEndpoint);

        if (response) {
            dispatch('fetchHeader');
            commit('SET_CURRENT_FETCH_STATUS', true);
            commit('SET_CURRENT_ORDER_MESSAGE_FINAL', state.translations?.edit_order?.edited_reservation);
            commit('SET_CURRENT_ORDER_MESSAGE_FINAL_DESC', state.translations?.edit_order?.email_reservation);
            return { status: true };
        }

    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        commit('SET_CURRENT_ORDER_MESSAGE_FINAL', error.data.errors[0]);
        return { status: false };
    }
    commit('SET_CURRENT_FETCH_STATUS', false);
    commit('SET_CURRENT_ORDER_MESSAGE_FINAL', state.translations.order_error);
    return { status: false };
}

async function setEditStatus({ commit }, payload) {
    commit('SET_CURRENT_EDIT_STATUS', payload);
}

async function editListProduct({ state, dispatch }) {
    const newProductList = Helpers.fuseTwoObject({
        obj1: state.productList,
        obj2: state.productSelected,
        valueComparate: 'id',
        valueToMerge: 'quantity',
    });
    dispatch('setProductList', newProductList);
}

function cleanStepper({ dispatch }) {
    dispatch('setTime', '');
    dispatch('setSelectionDay', '');
    dispatch('setProductList', []);
    dispatch('filterProduct');
    dispatch('setProducts', []);
}

async function fetchFaqs({ commit }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const result = await Faqs.get();
        if (result) {
            commit('SET_CURRENT_FAQS', result);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function fetchNews({ commit }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const result = await News.get();
        if (result) {
            commit('SET_CURRENT_NEWS', result);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function fetchLegal({ commit }) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const result = await Legal.get();
        if (result) {
            commit('SET_CURRENT_LEGAL', result);
            commit('SET_CURRENT_FETCH_STATUS', false);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}
async function setStore({ commit, dispatch }, payload) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const urlOrder = '/api/set_user_store/:id/';
        const updateEndpoint = urlOrder.replace(':id', payload);
        const response = await Stores.postSetUserStore(updateEndpoint);
        if (response) {
            saveLang(response.store_language);
            dispatch('fetchHeader');
            if (router.currentRoute.fullPath !== URL_CONSTANT.home) router.push(URL_CONSTANT.home);
        }
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        console.log(error);
    }
}

async function register({ commit }, payload) {
    try {
        commit('SET_CURRENT_FETCH_LOGIN', true);
        const response = await ExternalUser.register(payload);
        if (response) {
            commit('SET_CURRENT_FETCH_LOGIN', false);
            return {
                title: 'The user has been registered',
                subtitle: 'You need to wait until the  Canteen Manager activates your account.',
            };
        }
        return { title: 'Unknown Error' };
    } catch (error) {
        const listError = Helpers.mergeArraysInsideOfObject(error.data);
        commit('SET_CURRENT_ERROR_LIST', listError);
        commit('SET_CURRENT_FETCH_LOGIN', false);
        return null;
    }
}

async function login({ commit }, payload) {
    try {
        commit('SET_CURRENT_FETCH_LOGIN', true);
        const response = await ExternalUser.login(payload);
        if (response) {
            saveAccessToken(response.access);
            saveRefreshToken(response.refresh);
            saveLang(response.store_language);
            saveIsIngkaUser(0);
            commit('SET_CURRENT_FETCH_LOGIN', false);
            router.push(URL_CONSTANT.home);
        }
    } catch (error) {
        const listError = Helpers.mergeArraysInsideOfObject(error.data);
        commit('SET_CURRENT_ERROR_LIST', listError);
        commit('SET_CURRENT_FETCH_LOGIN', false);
    }
}

async function passwordReset({ commit }, payload) {
    try {
        commit('SET_CURRENT_FETCH_LOGIN', true);
        const response = await ExternalUser.passwordReset(payload);
        if (response) {
            commit('SET_CURRENT_FETCH_LOGIN', false);
            commit('SET_CURRENT_RESET_PASS_RESPONSE', ['An email with a reset link was sent']);
            return true;
        }
        commit('SET_CURRENT_RESET_PASS_RESPONSE', ['Error in the server']);
        return false;
    } catch (error) {
        const listError = Helpers.mergeArraysInsideOfObject(error.data);
        commit('SET_CURRENT_FETCH_LOGIN', false);
        commit('SET_CURRENT_RESET_PASS_RESPONSE', listError);
        return false;
    }
}

async function passVerifyToken({ commit }, payload) {
    try {
        commit('SET_CURRENT_FETCH_STATUS', true);
        const response = await ExternalUser.passVerifyToken(payload);
        if (response) {
            commit('SET_CURRENT_FETCH_STATUS', false);
            return true;
        }
        commit('SET_CURRENT_RESET_PASS_RESPONSE', ['Error in the server']);
        return false;
    } catch (error) {
        commit('SET_CURRENT_FETCH_STATUS', false);
        commit('SET_CURRENT_RESET_PASS_RESPONSE', ['The token has expired']);
        return false;
    }
}

async function passConfirm({ commit }, payload) {
    try {
        commit('SET_CURRENT_FETCH_LOGIN', true);
        const response = await ExternalUser.passConfirm(payload);
        if (response) {
            commit('SET_CURRENT_FETCH_LOGIN', false);
            commit('SET_CURRENT_RESET_PASS_RESPONSE', ['The password has been changed']);
            return true;
        }
        commit('SET_CURRENT_RESET_PASS_RESPONSE', ['Error in the server']);
        return false;
    } catch (error) {
        const listError = Helpers.mergeArraysInsideOfObject(error.data);
        commit('SET_CURRENT_FETCH_LOGIN', false);
        commit('SET_CURRENT_RESET_PASS_RESPONSE', listError);
        return false;
    }
}

function setErrorList({ commit }, payload) {
    commit('SET_CURRENT_ERROR_LIST', payload);
}

function setPassResponse({ commit }, payload) {
    commit('SET_CURRENT_RESET_PASS_RESPONSE', payload);
}

function setCurrentFetchStatus({ commit }, payload) {
    commit('SET_CURRENT_FETCH_STATUS', payload);
}

function setFraqTime({ commit }, payload) {
    commit('SET_FRAQ_TIME', payload);
}

function setMenuId({ commit }, payload) {
    commit('SET_MENU_ID', payload);
}

function setActiveOrderId({ commit }, payload) {
    commit('SET_CURRENT_ACTIVE_ORDER_ID', payload);
}

export default {
    fetchTimes,
    setTime,
    fetchProduct,
    setProducts,
    createOrder,
    fetchActiveOrders,
    fetchLastActiveOrder,
    updateOrder,
    fetchHeader,
    fetchCategories,
    filterProduct,
    createFeedback,
    countProduct,
    fetchCalender,
    setSelectionDay,
    setProductList,
    editOrder,
    editListProduct,
    setEditStatus,
    cleanStepper,
    modifyActualOrder,
    checkStep,
    fetchFaqs,
    fetchNews,
    fetchLegal,
    setShiftName,
    setOrderType,
    fetchActiveStores,
    setStore,
    register,
    login,
    setErrorList,
    setPassResponse,
    passwordReset,
    passVerifyToken,
    passConfirm,
    fetchActiveCountrys,
    setCurrentFetchStatus,
    setFraqTime,
    setMenuId,
    setActiveOrderId,
};
