/* eslint-disable  no-param-reassign */
/* eslint-disable  no-return-assign */
const SET_CURRENT_TIME_LIST = (state, payload) => state.timeList = payload;
const SET_CURRENT_SELECTION_DAY = (state, payload) => state.selectionDay = payload;
const SET_CURRENT_SELECTION_DAY_PRINT = (state, payload) => state.selectioDayPrint = payload;
const SET_CURRENT_SELECTION_DAY_TRANSL = (state, payload) => state.selectionDayTransl = payload;
const SET_CURRENT_TIME_SELECTED = (state, payload) => state.timeSelected = payload;
const SET_CURRENT_PRODUCT_LIST = (state, payload) => {
    const productList = payload.map((each) => {
        if (!each.quantity) {
            return { ...each, quantity: 0 };
        }
        return each;
    });
    state.productList = productList;
};
const SET_CURRENT_PRODUCT_SELECTED = (state, payload) => state.productSelected = payload;
const SET_CURRENT_STORE_ID = (state, payload) => state.storeId = payload;
const SET_CURRENT_ACTIVE_ORDERS = (state, payload) => state.activeOrders = payload;
const SET_CURRENT_LAST_ACTIVE_ORDER = (state, payload) => state.lastActiveOrder = payload;
const SET_CURRENT_COUNT_ACTIVE_ORDERS = (state, payload) => state.countActiveOrders = payload;
const SET_CURRENT_COUNT_NEWS = (state, payload) => state.countNews = payload;
const SET_CURRENT_SELECTION_ALLOWED = (state, payload) => state.selectionAllowed = payload;
const SET_CURRENT_OPENING_TIME = (state, payload) => state.openingTime = payload;
const SET_CURRENT_CLOSING_TIME = (state, payload) => state.closingTime = payload;
const SET_CURRENT_CATEGORIES = (state, payload) => state.categories = payload;
const SET_CURRENT_FILTER_PRODUCT_LIST = (state, payload) => state.filterProductList = payload;
const SET_CURRENT_PRODUCT_COUNT = (state, payload) => state.productCount = payload;
const SET_CURRENT_CALENDER = (state, payload) => state.calender = payload;
const SET_CURRENT_FETCH_STATUS = (state, payload) => state.fetchStatus = payload;
const SET_CURRENT_EDIT_ID_ORDER = (state, payload) => state.editIdOrder = payload;
const SET_CURRENT_ACTIVE_ORDER_ID = (state, payload) => state.activeOrderId = payload;
const SET_CURRENT_EDIT_STATUS = (state, payload) => state.editStatus = payload;
const SET_CURRENT_EDIT_OBSERVATION = (state, payload) => state.editObservation = payload;
const SET_CURRENT_ORDER_MESSAGE_FINAL = (state, payload) => state.orderMessageFinal = payload;
const SET_CURRENT_ORDER_MESSAGE_FINAL_DESC = (state, payload) => state.orderMessageFinalDesc = payload;
const SET_CURRENT_ORDER_STATUS_ERROR = (state, payload) => state.orderStatusError = payload;
const SET_CURRENT_FIRST_NAME = (state, payload) => state.firstName = payload;
const SET_CURRENT_ADMIN_ACCESS = (state, payload) => state.adminAccess = payload;
const SET_CURRENT_ID_INGKA = (state, payload) => state.idIngka = payload;
const SET_CURRENT_LANGUAGE = (state, payload) => state.language = payload;
const SET_CURRENT_TRASLATIONS = (state, payload) => state.translations = payload;
const SET_CURRENT_FAQS = (state, payload) => state.faqs = payload;
const SET_CURRENT_NEWS = (state, payload) => state.news = payload;
const SET_CURRENT_LEGAL = (state, payload) => state.legal = payload;
const SET_CURRENT_SHIFT_NAMES = (state, payload) => state.shiftNames = payload;
const SET_CURRENT_SELECTION_SHIFT_NAME = (state, payload) => state.selectionShiftName = payload;
const SET_CURRENT_ORDER_TYPE = (state, payload) => state.orderType = payload;
const SET_CURRENT_ACTIVE_STORES = (state, payload) => state.activeStores = payload;
const SET_CURRENT_STORE_NAME = (state, payload) => state.storeName = payload;
const SET_CURRENT_CURRENCY = (state, payload) => state.currency = payload;
const SET_CURRENT_FETCH_LOGIN = (state, payload) => state.fetchLogin = payload;
const SET_CURRENT_ERROR_LIST = (state, payload) => state.errorList = payload;
const SET_CURRENT_RESET_PASS_RESPONSE = (state, payload) => state.resetPassResponse = payload;
const SET_COUNTRY_STORES = (state, payload) => state.countryStores = payload;
const SET_COUNTRY_CODE = (state, payload) => state.countryCode = payload;
const SET_FRAQ_TIME = (state, payload) => state.fraqTime = payload;
const SET_MENU_ID = (state, payload) => state.menuId = payload;

export default {
    SET_CURRENT_TIME_LIST,
    SET_CURRENT_SELECTION_DAY,
    SET_CURRENT_TIME_SELECTED,
    SET_CURRENT_PRODUCT_LIST,
    SET_CURRENT_PRODUCT_SELECTED,
    SET_CURRENT_SELECTION_DAY_PRINT,
    SET_CURRENT_SELECTION_DAY_TRANSL,
    SET_CURRENT_STORE_ID,
    SET_CURRENT_ACTIVE_ORDERS,
    SET_CURRENT_LAST_ACTIVE_ORDER,
    SET_CURRENT_COUNT_ACTIVE_ORDERS,
    SET_CURRENT_COUNT_NEWS,
    SET_CURRENT_SELECTION_ALLOWED,
    SET_CURRENT_CLOSING_TIME,
    SET_CURRENT_OPENING_TIME,
    SET_CURRENT_CATEGORIES,
    SET_CURRENT_FILTER_PRODUCT_LIST,
    SET_CURRENT_PRODUCT_COUNT,
    SET_CURRENT_CALENDER,
    SET_CURRENT_FETCH_STATUS,
    SET_CURRENT_EDIT_ID_ORDER,
    SET_CURRENT_ACTIVE_ORDER_ID,
    SET_CURRENT_EDIT_STATUS,
    SET_CURRENT_EDIT_OBSERVATION,
    SET_CURRENT_ORDER_MESSAGE_FINAL,
    SET_CURRENT_ORDER_MESSAGE_FINAL_DESC,
    SET_CURRENT_ORDER_STATUS_ERROR,
    SET_CURRENT_FIRST_NAME,
    SET_CURRENT_ADMIN_ACCESS,
    SET_CURRENT_ID_INGKA,
    SET_CURRENT_LANGUAGE,
    SET_CURRENT_TRASLATIONS,
    SET_CURRENT_FAQS,
    SET_CURRENT_NEWS,
    SET_CURRENT_LEGAL,
    SET_CURRENT_SHIFT_NAMES,
    SET_CURRENT_SELECTION_SHIFT_NAME,
    SET_CURRENT_ORDER_TYPE,
    SET_CURRENT_ACTIVE_STORES,
    SET_CURRENT_STORE_NAME,
    SET_CURRENT_CURRENCY,
    SET_CURRENT_FETCH_LOGIN,
    SET_CURRENT_ERROR_LIST,
    SET_CURRENT_RESET_PASS_RESPONSE,
    SET_COUNTRY_STORES,
    SET_COUNTRY_CODE,
    SET_FRAQ_TIME,
    SET_MENU_ID,
};
