/* eslint-disable consistent-return */
/**
 * Common methods to make AJAX calls
 */
import axios from 'axios';

import { getAccessToken, getLang } from '../../services/localStorage/jwt';

const domain = process.env.DOMAIN;

function getCommonHeaders() {
    return {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json',
        'Accept-Language': getLang(),
    };
}

function getCommonAuthHeaders() {
    return {
        ...getCommonHeaders(),
        Authorization: `Bearer ${getAccessToken()}`,
    };
}

axios.interceptors.request.use((config) => {
    // Do something before request is sent
    let newHeaders = null;

    if (config.headers.isAuth) {
        newHeaders = getCommonAuthHeaders();
        delete newHeaders.isAuth;
    } else {
        newHeaders = getCommonHeaders();
    }

    return { ...config, headers: newHeaders };
}, (error) => {
    // Do something with request error
    Promise.reject(error);
});

export default {
    /**
     * Makes a GET request to the API.
    */
    get: async (url, params = {}, responseConfig = {}) => {
        try {
            const response = await axios.get(
                domain + url,
                { params, ...responseConfig },
            );

            return response.data;
        } catch (error) {
            const typeError = error.response.status;
            switch (typeError) {
            case 404:
                window.location = '/#/error/404';
                break;
            case 500:
                window.location = '/#/error/500';
                break;
            case 503:
                window.location = '/#/error/503';
                break;
            default:
                break;
            }
            throw error.response;
        }
    },

    /**
     * Makes a POST request to the API.
 */
    post: async (url, data, responseConfig = {}) => {
        try {
            const response = await axios.post(
                domain + url,
                { ...data },
                responseConfig,
            );

            return response.data;
        } catch (error) {
            const typeError = error.response.status;
            switch (typeError) {
            case 404:
                window.location = '/#/error/404';
                break;
            case 500:
                window.location = '/#/error/500';
                break;
            case 503:
                window.location = '/#/error/503';
                break;
            default:
                break;
            }
            throw error.response;
        }
    },

    /**
     * Makes a PUT request to the API.
 */
    put: async (url, data, responseConfig = {}) => {
        try {
            const response = await axios.put(
                domain + url,
                { ...data },
                responseConfig,
            );

            return response.data;
        } catch (error) {
            const typeError = error.response.status;
            switch (typeError) {
            case 404:
                window.location = '/#/error/404';
                break;
            case 500:
                window.location = '/#/error/500';
                break;
            case 503:
                window.location = '/#/error/503';
                break;
            default:
                break;
            }
            throw error.response;
        }
    },
    /**
     * Makes a Patch request to the API.
 */
    patch: async (url, data, responseConfig = {}) => {
        try {
            const response = await axios.patch(
                domain + url,
                { ...data },
                responseConfig,
            );

            return response.data;
        } catch (error) {
            const typeError = error.response.status;
            switch (typeError) {
            case 404:
                window.location = '/#/error/404';
                break;
            case 500:
                window.location = '/#/error/500';
                break;
            case 503:
                window.location = '/#/error/503';
                break;
            default:
                break;
            }
            throw error.response;
        }
    },

    /**
     * Makes a DELETE request to the API.
 */
    delete: async (url, responseConfig = {}) => {
        try {
            const response = await axios.delete(
                domain + url,
                responseConfig,
            );

            return response.data;
        } catch (error) {
            const typeError = error.response.status;
            switch (typeError) {
            case 404:
                window.location = '/#/error/404';
                break;
            case 500:
                window.location = '/#/error/500';
                break;
            case 503:
                window.location = '/#/error/503';
                break;
            default:
                break;
            }
            throw error.response;
        }
    },
};
