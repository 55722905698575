/* eslint-disable import/no-cycle */
import { createRouter, createWebHashHistory } from 'vue-router';

import { getAccessToken } from '../services/localStorage/jwt';
import urlConstants from './url';

urlConstants.MAIN_LAYOUT.children = [
    urlConstants.HOME_PAGE_ROUTE,
    urlConstants.STEP_DAY,
    urlConstants.STEP_TIME,
    urlConstants.STEP_PRODUCTS,
    urlConstants.STEP_CART,
    urlConstants.STEP_ORDER_CONFIRMED,
    urlConstants.ACTIVE_ORDERS,
    urlConstants.NEWS,
    urlConstants.FEEDBACK,
    urlConstants.FAQ,
    urlConstants.LEGAL,
    urlConstants.CANCEL_EMAIL_NOTIFICATION,
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        // TOKEN_LAYOUT,
        urlConstants.SIGN_UP,
        urlConstants.LOGIN,
        urlConstants.EXTERNAL_LOGIN,
        urlConstants.RESET_PASSWORD,
        urlConstants.TOKEN_ACCESS,
        urlConstants.MAIN_LAYOUT,
        urlConstants.NOT_FOUND,
        urlConstants.ERROR_500,
        urlConstants.ERROR_503,
    ],
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !getAccessToken() && to.name !== 'auth') {
        window.location = '/';
    } else {
        next();
    }
});

export default router;
