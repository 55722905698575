const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const IS_INGKA_USER = 'IS_INGKA_USER';
const LANGUAGE = 'LANGUAGE';

export function saveAccessToken(access) {
    localStorage.setItem(ACCESS_TOKEN, access);
}

export function saveRefreshToken(access) {
    localStorage.setItem(REFRESH_TOKEN, access);
}

export function saveIsIngkaUser(access) {
    localStorage.setItem(IS_INGKA_USER, access);
}

export function saveLang(lang) {
    localStorage.setItem(LANGUAGE, lang);
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
}

export function geteIsIngkaUser() {
    return localStorage.getItem(IS_INGKA_USER);
}

export function getLang() {
    return localStorage.getItem(LANGUAGE);
}

export function clearAccessToken() {
    localStorage.clear(ACCESS_TOKEN);
}

export function clearRefreshToken() {
    localStorage.clear(REFRESH_TOKEN);
}

export function clearIsIngkaUser() {
    localStorage.clear(IS_INGKA_USER);
}

export function clearLang() {
    localStorage.clear(LANGUAGE);
}
