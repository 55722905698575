import methods from './methods';

const ACTIVE_STORES = '/api/active_stores/:country/';
const SET_USER_STORE = '/api/set_user_store/:id/';
const ACTIVE_COUNTRYS = '/api/active_country_stores/';

export default {
    getAllActiveStores: (endpoint = ACTIVE_STORES) => methods.get(endpoint, {}, { headers: { isAuth: true } }),
    getAllCountryStores: (endpoint = ACTIVE_COUNTRYS) => methods.get(endpoint, {}, { headers: { isAuth: true } }),
    postSetUserStore: (endpoint = SET_USER_STORE) => methods.put(endpoint, {}, { headers: { isAuth: true } }),
};
