/* eslint-disable import/no-cycle */
import URL_CONSTANT from './urlConstant';
import { verifyToken, verifyLocalStorage } from '../Token/jwt';
import store from '../store/index';

export default {
    SIGN_UP: {
        name: 'SignUp',
        path: URL_CONSTANT.signUp,
        beforeEnter: verifyLocalStorage,
        component: () => import(
            /* webpackChunkName: "signUp" */
            '../containers/SignUp/SingUp.vue'
        ),
    },
    LOGIN: {
        name: 'Login',
        path: URL_CONSTANT.Login,
        beforeEnter: verifyLocalStorage,
        component: () => import(
            /* webpackChunkName: "Login" */
            '../containers/Login/login.vue'
        ),
    },
    EXTERNAL_LOGIN: {
        name: 'ExternalLogin',
        path: URL_CONSTANT.externalLogin,
        beforeEnter: verifyLocalStorage,
        component: () => import(
            /* webpackChunkName: "extenarlLogin" */
            '../containers/ExternalLogin/externalLogin.vue'
        ),
    },
    RESET_PASSWORD: {
        name: 'ResetPass',
        path: URL_CONSTANT.resetPass,
        beforeEnter: verifyLocalStorage,
        component: () => import(
            /* webpackChunkName: "resetPassword" */
            '../containers/ResetPassword/resetPassword.vue'
        ),
    },
    HOME_PAGE_ROUTE: {
        name: 'Home',
        path: URL_CONSTANT.home,
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "homePage" */
            '../containers/Home/home.vue'
        ),
    },
    STEP_DAY: {
        name: 'StepDay',
        path: URL_CONSTANT.stepperDay,
        beforeEnter: (to, from, next) => {
            verifyToken(to, from, next);
            store.dispatch('checkStep');
        },
        component: () => import(
            /* webpackChunkName: "stepperDay" */
            '../containers/StepDay/stepDay.vue'
        ),
    },
    STEP_TIME: {
        name: 'StepTime',
        path: URL_CONSTANT.stepperTime,
        beforeEnter: (to, from, next) => {
            verifyToken(to, from, next);
            store.dispatch('checkStep');
        },
        component: () => import(
            /* webpackChunkName: "stepTime" */
            '../containers/StepTime/stepTime.vue'
        ),
    },
    STEP_PRODUCTS: {
        name: 'StepProducts',
        path: URL_CONSTANT.stepperMeal,
        beforeEnter: (to, from, next) => {
            verifyToken(to, from, next);
            store.dispatch('checkStep');
        },
        component: () => import(
            /* webpackChunkName: "stepProducts" */
            '../containers/StepProducts/stepProducts.vue'
        ),
    },
    STEP_CART: {
        name: 'StepCart',
        path: URL_CONSTANT.stepperCart,
        beforeEnter: (to, from, next) => {
            verifyToken(to, from, next);
            store.dispatch('checkStep');
        },
        component: () => import(
            /* webpackChunkName: "stepCart" */
            '../containers/StepCart/stepCart.vue'
        ),
    },
    STEP_ORDER_CONFIRMED: {
        name: 'StepOrderCheck',
        path: URL_CONSTANT.stepperFinal,
        beforeEnter: (to, from, next) => {
            verifyToken(to, from, next);
            store.dispatch('checkStep');
        },
        component: () => import(
            /* webpackChunkName: "stepConfirmed" */
            '../containers/OrderConfirmed/orderConfirmed.vue'
        ),
    },
    CANCEL_EMAIL_NOTIFICATION: {
        name: 'emailDisabled',
        path: URL_CONSTANT.emailDisabled,
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "emailDisabled" */
            '../containers/emailDisabled/emailDisabled.vue'
        ),
    },
    TOKEN_ACCESS: {
        name: 'Token',
        path: URL_CONSTANT.token,
        component: () => import(
            /* webpackChunkName: "token" */
            '../Token/tokenComponent/token.vue'
        ),
    },
    ACTIVE_ORDERS: {
        name: 'ActiveOrders',
        path: URL_CONSTANT.activeOrders,
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "activeOrders" */
            '../containers/ActiveOrders/activeOrders.vue'
        ),
    },
    NEWS: {
        name: 'news',
        path: URL_CONSTANT.news,
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "news" */
            '../containers/News/news.vue'
        ),
    },
    FEEDBACK: {
        name: 'Feedback',
        path: URL_CONSTANT.feedback,
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "opinion" */
            '../containers/Opinion/opinion.vue'
        ),
    },
    FAQ: {
        name: 'FAQ',
        path: URL_CONSTANT.faq,
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "faq" */
            '../containers/FAQ/faq.vue'
        ),
    },
    LEGAL: {
        name: 'Legal',
        path: URL_CONSTANT.legal,
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "legal" */
            '../containers/Legal/legal.vue'
        ),
    },
    MAIN_LAYOUT: {
        path: '/',
        beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "main" */
            '../layout/main/main.vue'
        ),
    },
    NOT_FOUND: {
        name: 'NotFound',
        path: '/:catchAll(.*)*',
        // beforeEnter: verifyToken,
        component: () => import(
            /* webpackChunkName: "error404" */
            '../containers/Error404/error404.vue'
        ),
    },
    ERROR_500: {
        path: URL_CONSTANT.error500,
        component: () => import(
            /* webpackChunkName: "error500" */
            '../containers/Error500/error500.vue'
        ),
    },
    ERROR_503: {
        path: URL_CONSTANT.error503,
        component: () => import(
            /* webpackChunkName: "error503" */
            '../containers/Error503/error503.vue'
        ),
    },
};
