import { register } from 'register-service-worker';

export default function registerSW(fetchStatus) {
    if (process.env.NODE_ENV === 'production') {
        register('/service-worker.js', {
            registrationOptions: { scope: './' },
            ready() {
                console.log('Service worker is active.');
            },
            registered() {
                console.log('Service worker has been registered.');
            },
            cached(registration) {
                console.log('Content has been cached for offline use.');
                if (registration) {
                    window.location.reload();
                    window.location = '/';
                }
            },
            async updatefound() {
                console.log('New content is downloading.');
                fetchStatus(true);
            },
            updated(registration) {
                console.log('New content is available; please refresh.');
                if (registration && registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                    console.log('skiped');
                    window.location.reload();
                    window.location = '/';
                }
            },
            offline() {
                console.log('No internet connection found. App is running in offline mode.');
            },
            error(error) {
                console.error('Error during service worker registration:', error);
            },
        });
    }
}
