import { createGtm } from '@gtm-support/vue-gtm';

export default createGtm({
    id: process.env.GTM_ID,
    queryParams: {
        gtm_auth: process.env.GTM_AUTH,
        gtm_preview: process.env.GTM_PREVIEW,
        gtm_cookies_win: process.env.GTM_COOKIES_WIN,
    },
    enabled: false,
});
