import methods from './methods';

const CREATE_ORDER = '/api/create_order/';
const ACTIVE_ORDERS = '/api/active_orders/';
const UPADTE_ORDER = '​/api/update_order/:id/';
const MODIFY_ORDER = '/api/modify_order/:id/';

export default {
    createOrder: (data, endpoint = CREATE_ORDER) => methods.post(endpoint, data, { headers: { isAuth: true } }),
    activeOrders: (endpoint = ACTIVE_ORDERS) => methods.get(endpoint, {}, { headers: { isAuth: true } }),
    updateOrder: (data, endpoint = UPADTE_ORDER) => methods.post(endpoint, data, { headers: { isAuth: true } }),
    modifyOrder: (data, endpoint = MODIFY_ORDER) => methods.put(endpoint, data, { headers: { isAuth: true } }),
};
