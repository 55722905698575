export default {
    doneTimeList: (state) => state.timeList,
    doneSelectionDay: (state) => state.selectionDay,
    doneSelectionDayPrint: (state) => state.selectioDayPrint,
    doneSelectionDayTransl: (state) => state.selectionDayTransl,
    doneTimeSelected: (state) => state.timeSelected,
    doneProductList: (state) => state.productList,
    doneProductSelected: (state) => state.productSelected,
    doneStoreId: (state) => state.storeId,
    doneActiveOrders: (state) => state.activeOrders,
    doneLastActiveOrder: (state) => state.lastActiveOrder,
    doneCountActiveOrders: (state) => state.countActiveOrders,
    doneCountNews: (state) => state.countNews,
    doneSelectionAllowed: (state) => state.selectionAllowed,
    doneOpeningTime: (state) => state.openingTime,
    doneClosingTime: (state) => state.closingTime,
    doneCategories: (state) => state.categories,
    doneFilterProductList: (state) => state.filterProductList,
    doneProductCount: (state) => state.productCount,
    doneCalender: (state) => state.calender,
    doneFetchStatus: (state) => state.fetchStatus,
    doneEditIdOrder: (state) => state.editIdOrder,
    doneActiveOrderId: (state) => state.activeOrderId,
    doneEditStatus: (state) => state.editStatus,
    doneEditObservation: (state) => state.editObservation,
    doneOrderMessageFinal: (state) => state.orderMessageFinal,
    doneOrderMessageFinalDesc: (state) => state.orderMessageFinalDesc,
    doneOrderStatusError: (state) => state.orderStatusError,
    doneFirstName: (state) => state.firstName,
    doneAdminAccess: (state) => state.adminAccess,
    doneLanguage: (state) => state.language,
    doneIdShop: (state) => state.idIngka,
    doneTranslations: (state) => state.translations,
    doneFaqs: (state) => state.faqs,
    doneNews: (state) => state.news,
    doneLegal: (state) => state.legal,
    doneShiftNames: (state) => state.shiftNames,
    doneSelectionShiftName: (state) => state.selectionShiftName,
    doneOrderType: (state) => state.orderType,
    doneActiveStores: (state) => state.activeStores,
    doneStoreName: (state) => state.storeName,
    doneCurrency: (state) => state.currency,
    doneFetchLogin: (state) => state.fetchLogin,
    doenErrorList: (state) => state.errorList,
    doneResetPassResponse: (state) => state.resetPassResponse,
    doneCountryStores: (state) => state.countryStores,
    doneCountryCode: (state) => state.countryCode,
    doneFraqTime: (state) => state.fraqTime,
};
