import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import components from './components';
import data from './data';
import methods from './methods';
import props from './props';
import computed from './computed';
import RegisterSW from '../../registerServiceWorker';

export default {
    components,
    data,
    props,
    methods,
    computed,
    async created() {
        RegisterSW(this.setCurrentFetchStatus);
    },
};
