/* eslint-disable max-statements */
import {
    getAccessToken,
    getRefreshToken,
    geteIsIngkaUser,
    clearAccessToken,
    clearRefreshToken,
    saveAccessToken,
    saveRefreshToken,
    clearIsIngkaUser,
    clearLang,
} from '../services/localStorage/jwt';
import api from '../store/api/authentication_jwt';

const domain = process.env.DOMAIN;

export async function verifyTokenAction(access, isIngkaUser) {
    let verifyResponse = null;
    try {
        verifyResponse = await api.verifyJWT(access, isIngkaUser);
        if (!verifyResponse) {
            return null;
        }
    } catch (error) {
        return null;
    }
    return access;
}

export async function refreshTokenAction(refreshToken, isIngkaUser) {
    let verifyResponse = false;
    try {
        const refreshApi = isIngkaUser ? api.refreshJWT : api.refreshExternalJWT;
        verifyResponse = await refreshApi(refreshToken, isIngkaUser);
        if (!verifyResponse) {
            return false;
        }
        saveAccessToken(verifyResponse.access_token);
        saveRefreshToken(verifyResponse.refresh_token);
    } catch (error) {
        return false;
    }
    return true;
}

export function redirectToLogOut() {
    window.location = `${domain}/api/accounts/logout`;
}

export function redirectToLogOutIngkaUser() {
    window.location = `${domain}/api/oauth2/logout`;
}

// eslint-disable-next-line max-statements
// eslint-disable-next-line complexity
export async function verifyToken(to, from, next) {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const isIngkaUser = geteIsIngkaUser();
    if (accessToken) {
        const response = await verifyTokenAction(accessToken, isIngkaUser);
        if (response) {
            next();
        } else {
            const responseRefresh = await refreshTokenAction(refreshToken, isIngkaUser);
            if (responseRefresh) {
                next();
            } else {
                clearToken();
            }
        }
    } else {
        next({ name: 'SignInOptions' });
    }
}

export function verifyLocalStorage(to, from, next) {
    const accessToken = getAccessToken();
    if (!accessToken) {
        next();
    } else {
        next({ name: 'Home' });
    }
}

function clearToken() {
    clearAccessToken();
    clearRefreshToken();
    clearIsIngkaUser();
    clearLang();
}
