export default {
    login: '/',
    externalLogin: '/login',
    signUp: '/register',
    resetPass: '/resetPass/:token?',
    home: '/home',
    stepper: '/stepper',
    stepperDay: '/stepper-day',
    stepperTime: '/stepper-time',
    stepperMeal: '/stepper-meal',
    stepperCart: '/stepper-cart',
    stepperFinal: '/stepper-final',
    token: '/auth/:access/:refresh/:isIngkaUser/:lang',
    activeOrders: '/activeOrders',
    news: '/news',
    feedback: '/feedback',
    faq: '/faq',
    legal: '/legal',
    emailDisabled: '/emailDisabled',
    error404: '/error/404',
    error500: '/error/500',
    error503: '/error/503',
};
