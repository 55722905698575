/* eslint-disable import/no-extraneous-dependencies */
import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import router from './src/routes';
import store from './src/store';
import App from './src/App.vue';
import Gtm from './src/gtm/gtmConfig';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueCookies);
app.use(Gtm);

app.mount('#app-ikeafood');
