<div class="app">
    <router-view></router-view>
    <div class="container-loader" v-if="doneFetchStatus">
        <div class="loader">
            <div
                style="width: 3rem; height: 3rem;"
                class="spinner-grow"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>