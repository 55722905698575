function getDateFormat(date, formatYear = false) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    let dateFormat = `${mm}-${dd}-${yyyy}`;
    if (formatYear) {
        dateFormat = `${yyyy}-${mm}-${dd}`;
    }
    return dateFormat;
}

function fuseTwoObject({
    obj1, obj2, valueComparate, valueToMerge,
}) {
    const objectTemp = [...obj1];
    for (let i = 0; i < obj1.length; i += 1) {
        for (let j = 0; j < obj2.length; j += 1) {
            if (objectTemp[i][valueComparate] === obj2[j][valueComparate]) {
                objectTemp[i][valueToMerge] = obj2[j][valueToMerge];
            }
        }
    }
    return objectTemp;
}

function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

function mergeArraysInsideOfObject(obj) {
    const listError = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [keyIgnored, value] of Object.entries(obj)) {
        listError.push(value);
    }
    return [].concat(...listError);
}

/* eslint-disable complexity */
function isEmail(email) {
    const regexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(regexEmail)) {
        return true;
    }
    return false;
}

function equalPass(pass1, pass2) {
    if (pass1 === pass2) {
        return true;
    }
    return false;
}

export default {
    getDateFormat,
    fuseTwoObject,
    isEmptyObject,
    mergeArraysInsideOfObject,
    isEmail,
    equalPass,
};
