export default {
    // Time Variables
    timeList: [],
    selectionDay: '',
    selectioDayPrint: '',
    selectionDayTransl: '',
    selectionShiftName: '',
    timeSelected: '',
    shiftNames: [],
    fraqTime: {},
    // Product variables
    productList: [],
    productSelected: [],
    filterProductList: [],
    productCount: 0,
    menuId: null,
    // Active Orders
    activeOrders: [],
    lastActiveOrder: {},
    countActiveOrders: 0,
    activeOrderId: '',
    countNews: 0,
    // store
    storeId: null,
    storeName: '',
    openingTime: '',
    closingTime: '',
    acceptLanguage: '',
    activeStores: [],
    countryStores: [],
    countryCode: '',
    // Categories
    categories: [],
    // Others
    selectionAllowed: true,
    // Calender
    calender: [],
    // Fecth
    fetchStatus: false,
    // Edit
    editStatus: false,
    editIdOrder: null,
    editObservation: '',
    // Order Confirmed
    orderMessageFinal: '',
    orderMessageFinalDesc: '',
    orderStatusError: false,
    orderType: '',
    // User
    firstName: '',
    adminAccess: false,
    idIngka: '',
    language: 'en',
    translations: {},
    faqs: {},
    news: [],
    legal: {},
    currency: '',
    // Login flow
    fetchLogin: false,
    errorList: [],
    resetPassResponse: '',
};
