import methods from './methods';

const VERIFY_URL = '/api/token/verify/';
const REFRESH_URL = '/api/token/refresh/';

export default {
    verifyJWT: (token, isIngkaUser, endpoint = VERIFY_URL) => (
        methods.post(endpoint, { token, is_ingka_user: isIngkaUser })
    ),
    refreshJWT: (refresh, isIngkaUser, endpoint = REFRESH_URL) => (
        methods.post(endpoint, { refresh, is_ingka_user: isIngkaUser })
    ),
    refreshExternalJWT: (refresh, isIngkaUser, endpoint = '/api/token-ext/refresh') => (
        methods.post(endpoint, { refresh, is_ingka_user: isIngkaUser })
    ),
};
